import React, { Component } from "react";
import styles from "./Header.module.scss";
import { FiExternalLink, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <Link to="/"><img src={`/img/logo_${this.props.theme == "light" ? "black": "white"}.svg`} /></Link>
                    <Link to="/" className={[styles.titleText, this.props.knockout ? styles.knockout : null].join(" ")}>
                        KaloSpoon<span className={styles.blink}>_</span>
                    </Link>
                </div>
                <div className={styles.hamburger}>
                    <FiMenu fontSize="32px" />
                </div>
                <div className={styles.menu}>
                    <Link to="/">Games</Link>
                    <Link to="/about">About</Link>
                    <a href="https://kalospoon.itch.io" target="_blank">itch.io <FiExternalLink /></a>
                    <Link to="/presskit" className={styles.button}>Press Kit</Link>
                </div>
            </div>
        )
    }
}

export default Header;