import React, { Component } from "react";
import styles from "./Card.module.scss";

class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={[styles.wrapper, this.props.knockout ? styles.knockout : null, this.props.light ? styles.light : null].join(" ")}>
                <div className={[styles.bg, this.props.background ? null : styles.autoBg].join(" ")} style={this.props.background ? { backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), ${this.props.background}`} : null}>
                    <span className={styles.title}>
                        {this.props.title}
                    </span>
                    <div className={styles.content}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

export default Card;