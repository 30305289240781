import React from "react";
import Header from "../Header";
import Card from "../Card";
import styles from "./App.module.scss";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import { SiItchDotIo, SiSteam } from "react-icons/si";
import { RiFacebookFill } from "react-icons/ri";
import { FaMoon } from "react-icons/fa";
import { HiSun } from "react-icons/hi";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { detect } from "detect-browser";
const browser = detect();


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: "light",
            isNotSafari: !(browser.name == "safari" || browser.os == "iOS")
        };
    }
    render() {
        return (
            <Router>
                <div className={[styles.app, styles[this.state.theme]].join(" ")}>
                    <ThemeToggle callback={this.changeTheme.bind(this)} theme={this.state.theme} />
                    <Header theme={this.state.theme} knockout={this.state.isNotSafari && !this.isDark()} />
                    <Switch>
                        <Route path="/presskit">
                            <PressKit />
                        </Route>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/">
                            <Games knockout={this.state.isNotSafari && !this.isDark()} />
                        </Route>
                    </Switch>
                </div>
            </Router>
        )
    }

    isDark() {
        return this.state.theme == "dark";
    }

    changeTheme() {
        if (this.state.theme == "light") {
            this.setState({
                theme: "dark",
            });
        } else {
            this.setState({
                theme: "light",
            });
        }
    }
}

export default App;

const ThemeToggle = (props) => {
    return (
        <div onClick={props.callback} className={[styles.theme, styles[props.theme]].join(" ")}>
            <FaMoon fontSize="20px" className={styles.moon} />
            <HiSun fontSize="24px" className={styles.sun} />
        </div>
    )
}

const PressKit = () => {
    return (
        <React.Fragment>
            <h1 className={styles.title}>Overward Presskit</h1>
            <Card light title="Coming Soon..." />
        </React.Fragment>
        
    )
}

const About = () => {
    return (
        <React.Fragment>
            <h1 className={styles.title}>About</h1>
            <Card knockout>
                <h2>KaloSpoon is an indie games developer based in Melbourne, Australia.</h2>
            </Card>
        </React.Fragment>
    )
}

const Games = ({ knockout }) => {
    return (
        <React.Fragment>
            <h1 className={styles.title}>Games</h1>
            <Card knockout={knockout}>
                <h1>OVERWARD</h1>
                <h2>Coming Soon...</h2>
            </Card>
            <Card  knockout={knockout} onClick={() => window.open("https://kaloger.itch.io/slime-mama", "_blank")} light background={"url('/img/slimemama.gif')"}>
                <h1>SLIME MAMA</h1>
                <h2>A pesky knight is digging his way into your peaceful home! Quickly feed slime mama to build up her strength before he arrives, so she can take down this threat to the valley. </h2>
            </Card>
            <Card  knockout={knockout} light background={"url('/img/planet.gif')"}>
                <h1>PLANET OF DELIRIUM</h1>
                <h2>2130 CE.</h2>
                <h2>The edge of the galaxy.</h2>
                <h2>Shot down by pirates, you crash land on infamous planet D7. </h2>
                <h2>Find your energy reserves & escape the planet of delirium... or be destroyed!</h2>
            </Card>
            <Card  knockout={knockout} title={"Follow us!"}>
                <div className={styles.social}>
                    {/* <a href="https://kalospoon.itch.io/" target="_blank">
                        <SiSteam fontSize={"72px"} />
                        <span>KaloSpoon</span>
                    </a> */}
                    <a href="https://kalospoon.itch.io/" target="_blank">
                        <SiItchDotIo fontSize={"72px"} />
                        <span>kalospoon</span>
                    </a>
                    <a href="https://twitter.com/kalospoon" target="_blank">
                        <AiFillTwitterCircle fontSize={"72px"} />
                        <span>@kalospoon</span>
                    </a>
                    <a href="https://instagram.com/kalospoon" target="_blank">
                        <AiFillInstagram fontSize={"72px"} />
                        <span>@kalospoon</span>
                    </a>
                    <a href="https://facebook.com/kalospoon" target="_blank">
                        <RiFacebookFill fontSize={"72px"} />
                        <span>KaloSpoon</span>
                    </a>
                </div>
            </Card>
        </React.Fragment>
    );
}